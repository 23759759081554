import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import "./App.scss";
import FPIR from "./components/FinancePrivacyIntakeRequest";
import SiteDown from "./components/SiteDown";

function App() {
  if (process.env.REACT_APP_SITE_MAINTENANCE === "enable") {
    return (
      <>
        <Header />
        <Router>
          {" "}
          <Route path="/" exact component={SiteDown} /> {/* catch all */}
          <Redirect path from="*" to="/" component={SiteDown} />
        </Router>
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Header />
        <Router>
          <Switch>
            <Route path="/" exact component={FPIR} />

            {/* catch all */}
            <Redirect path from="/*" to="/" component={FPIR} />
          </Switch>
        </Router>
        <Footer />
      </>
    );
  }
}

export default App;
